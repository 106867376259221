export default function Join() {
    return (
        <div style={{ width: '100%', height: '100dvh' }}>
            <iframe
                src="https://tally.so/r/nWo7ok"
                width="100%"
                height="100%"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                title="Tally Form"
                style={{ border: 'none', overflow: 'hidden' }}
                allowFullScreen
            ></iframe>
        </div>
    )
}
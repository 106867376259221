import { useNavigate } from "react-router-dom";


export default function Content() {

    const navigate = useNavigate()

    const handleSubmit = async () => {
        navigate('/join')
    };

    return (
        <main className="max-w-3xl min-h-screen flex flex-col lg:px-4 px-2 mx-auto">
            <nav className="w-full flex flex-col items-center justify-center lh:mt-16 mt-10 mb-10 mx-auto">
                <a href="https://sharely.in">
                    <span className="textBody-l1 bg-gradient-to-r from-primary1 to-primary2 text-transparent bg-clip-text">Sharely.in</span>
                </a>
            </nav>

            <div className="flex flex-col items-center justify-center text-center">
                <h1 className="lg:text-5xl text-4xl font-bold tracking-[-1.5px] leading-[1.2em] text-center lg:mt-[10vh] mt-[3vh] lg:mb-8 mb-4">Sharing made safe and simple</h1>
                <p className="lg:text-xl text-base leading-7 text-center mb-8">sharely is a secure and user-friendly platform that makes sharing files, images, videos, audios, links, and more, safe and simple.</p>
            </div>

            <div className="mt-8 lg:w-[90%] w-full flex flex-col gap-3 mx-auto">
                <h3 className="textBody-m1">Be the first to join</h3>
                <input
                    onClick={handleSubmit}
                    className="outline-primary1 bg-white lg:p-4 p-3 border rounded-lg"
                    placeholder="example@gmail.com"
                    type="text"
                />
                <button
                    onClick={handleSubmit}
                    type="submit"
                    className="bg-primary1 hover:bg-primary1/90 textBody-m1 text-white lg:p-4 p-3 shadow-lg rounded-lg">
                    Join the waitlist
                </button>

                <span className="text-[#a89fb3]">{`More than 150 professionals are waiting to use sharely.in`}</span>
            </div>
        </main>
    )
}
import Footer from "../components/Footer";
import Content from "../components/Content";


export default function Home() {
    return (
        <div>
            <Content />
            <Footer />
        </div>
    );
}

export default function Footer() {
    return (
        <footer className="mb-4 sticky bottom-0 flex flex-col gap-2 bg-white">
            <div className="p-4 flex items-center justify-center gap-2">
                <a href="https://www.facebook.com/profile.php?id=61560994756980" target="_blank" rel="noreferrer">
                    <span className="text-[#a89fb3] hover:underline">Facebook</span>
                </a>
                <span className="text-[#a89fb3] cursor-context-menu">.</span>
                <a href="https://www.instagram.com/sharelyapp" target="_blank" rel="noreferrer">
                    <span className="text-[#a89fb3] hover:underline">Instagram</span>
                </a>
                <span className="text-[#a89fb3] cursor-context-menu">.</span>
                <a href="https://twitter.com/sharelyapp" target="_blank" rel="noreferrer">
                    <span className="text-[#a89fb3] hover:underline">Twitter</span>
                </a>
            </div>
        </footer>
    )
}